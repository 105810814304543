<template>
  <div class="healthHourse">
    <iframe
      src="https://project.zhuneng.cn/d3m/#/s/a3eba1647f2a4915a9b1ff95328fe904"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "healthHourse",
  data() {
    return {};
  },
  destroyed() {},
  created() {},
  mounted() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.healthHourse {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
  